<template>
    <v-container fluid>
        <div class="pl-4 pr-0 pt-2 d-flex">
            <div class="pr-0 pt-4 left_filter">
                <setting-menu></setting-menu>
            </div>
            <div class="div_body pl-3 pr-0 mx-0">
                <div class="d-flex flex-row align-center mb-4">
                    <h1>
                        <template v-if="saved">
                            {{ $t('eav_property.editing', {name: name}) }}
                        </template>
                        <template v-else>
                            {{ $t('eav_property.creating') }}
                        </template>
                     </h1>
                    <Btn class="ml-4" text icon small :to="{name: 'eav.type.property', params: {slug: entity_type_slug}}" :title="$t('eav_property.plural')">
                        <v-icon small>mdi-arrow-left</v-icon>
                    </Btn>
                </div>

                <v-card>
                    <v-card-text>
                        <v-form>
                            <ValidationObserver v-slot="{ invalid, validated, passes, validate }">
                                <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="type">
                                    <SelectInput
                                        id="type"
                                        v-model="type"
                                        :label="$t('eav.type')"
                                        :items="types"
                                        :error="!valid"
                                        :error-messages="errors"
                                        hide-details
                                        class="mb-4"
                                    ></SelectInput>
                                </ValidationProvider>

                                <ValidationProvider v-if="type === 'dictionary'" rules="required" v-slot="{ errors, valid }" ref="entity_id">
                                    <SelectInput
                                        id="entity_id"
                                        v-model="linked_entity_type_id"
                                        :label="$t('eav.entity')"
                                        :items="entities"
                                        :error="!valid"
                                        :error-messages="errors"
                                        item-text="name"
                                        item-value="id"
                                        hide-details
                                        class="mb-4"
                                    ></SelectInput>
                                </ValidationProvider>

                                <v-card v-if="type === 'list'">
                                    <v-card-title>
                                        {{ $t('eav_property_enum.self') }}
                                        <v-spacer></v-spacer>
                                        <Btn small icon :title="$t('add')" color="secondary">
                                            <v-icon @click="openEnumDialog(null, -1)">mdi-plus-circle-outline</v-icon>
                                        </Btn>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-data-table
                                            dense
                                            hide-default-footer
                                            :items="enums"
                                            :headers="enumsHeader"
                                        >
                                            <template v-slot:item.id="{item, index}">
                                                <span>{{ item.id ? item.id : $t('eav_property_enum.new') }}</span>
                                                <Btn x-small icon @click="openEnumDialog(item, index)">
                                                    <v-icon>mdi-pencil-outline</v-icon>
                                                </Btn>
                                                <Btn x-small icon @click="onEnumDeleteBtnClick(index)">
                                                    <v-icon>mdi-trash-can-outline</v-icon>
                                                </Btn>
                                            </template>
                                        </v-data-table>

                                        <EavPropertyListEnumForm :value="editingListItem" @input="onEnumInput"/>
                                    </v-card-text>
                                </v-card>

                                <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="slug">
                                    <TextInput
                                        id="slug"
                                        v-model="slug"
                                        :label="$t('eav.slug')"
                                        :error="!valid"
                                        :error-messages="errors"
                                    ></TextInput>
                                </ValidationProvider>

                                <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="sort">
                                    <TextInput
                                        id="sort"
                                        v-model="sort"
                                        :label="$t('eav.sort')"
                                        :error="!valid"
                                        :error-messages="errors"
                                    ></TextInput>
                                </ValidationProvider>

                                <template v-for="lng in listLanguageNames">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" :ref="lng.id">
                                        <TextInput
                                            :id="`name_${lng.id}`"
                                            :label="$t('field_name') + ' ' + lng.name"
                                            :error="!valid"
                                            :error-messages="errors"
                                            v-model="translations[lng.id].name"
                                            require
                                        ></TextInput>
                                    </ValidationProvider>
                                </template>

                                <ValidationProvider rules="" v-slot="{ errors, valid }" ref="required">
                                    <v-checkbox
                                        id="sort"
                                        v-model="required"
                                        :label="$t('eav.required')"
                                        :error="!valid"
                                        :error-messages="errors"
                                        hide-details
                                    ></v-checkbox>
                                </ValidationProvider>

                                <ValidationProvider rules="" v-slot="{ errors, valid }" ref="required">
                                    <v-checkbox
                                        id="sort"
                                        v-model="searchable"
                                        :label="$t('eav.searchable')"
                                        :error="!valid"
                                        :error-messages="errors"
                                        hide-details
                                    ></v-checkbox>
                                </ValidationProvider>

<!--                                <ValidationProvider rules="required" v-slot="{ errors, valid }" ref="multiple">-->
<!--                                    <v-checkbox-->
<!--                                        id="multiple"-->
<!--                                        v-model="multiple"-->
<!--                                        :label="$t('eav.multiple')"-->
<!--                                        :error="!valid"-->
<!--                                        :error-messages="errors"-->
<!--                                        hide-details-->
<!--                                        class="mb-4"-->
<!--                                    ></v-checkbox>-->
<!--                                </ValidationProvider>-->

                                <Btn :loading="loading" v-if="saved" :disabled="invalid" @click="postItem">{{ $t('save') }}</Btn>
                                <Btn :loading="loading" v-else color="secondary" :disabled="invalid" @click="postItem">{{ $t('create') }}</Btn>
                            </ValidationObserver>
                        </v-form>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-container>
</template>
<script>

import {mapGetters} from "vuex"
import {mask} from 'vue-the-mask'
import SettingMenu from "../components/SettingMenu";
import TextInput from "../components/Form/TextInput";
import SelectInput from "../components/Form/SelectInput";
import Btn from "@/components/Form/Btn.vue";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import EavPropertyListEnumForm from "@/views/EavPropertyListEnumForm.vue";

export default {
    name: "EavPropertyForm",

    props: {
        entity_type_slug: {
            type: String,
            required: true
        },

        id: {
            type: [Number, String],
            required: true
        }
    },

    directives: {
        mask,
    },
    components: {
        EavPropertyListEnumForm,
        Btn,
        SettingMenu,
        TextInput,
        SelectInput,
        ValidationObserver,
        ValidationProvider
    },

    watch: {
        type: {
            handler: function (val) {
                if (val === 'dictionary') {
                    this.fetchEntities();
                }
            },
            immediate: true
        },
    },

    beforeMount() {
        this.listLanguageNames.forEach((lang) => {
            this.translations[lang.id] = {
                name: ""
            }
        })
    },

    data() {
        let langValues = {};

        return {
            loading: false,
            slug: null,
            name: '',
            sort: '500',
            multiple: false,
            required: false,
            type: null,
            searchable: false,
            errors: {},
            translations: {},
            types: [
                {
                    text: 'Строка',
                    value: 'string'
                },
                {
                    text: 'Число',
                    value: 'number'
                },
                {
                    text: 'Логическое',
                    value: 'boolean'
                },
                {
                    text: 'Дата и время',
                    value: 'datetime'
                },
                {
                    text: 'Дата',
                    value: 'date'
                },
                // {
                //     text: 'Файл',
                //     value: 'file'
                // },
                {
                    text: 'Список',
                    value: 'list'
                },
                {
                    text: 'Справочник',
                    value: 'dictionary'
                }
            ],
            editingListItem: null,
            linked_entity_type_id: null,
            entities: [],
            enums: [],
        };
    },

    computed: {
        ...mapGetters(['lang', 'listLanguageNames']),

        saved() {
            return this.id > 0
        },

        enumsHeader() {
            let headers = [
                {
                    text: 'ID',
                    align: 'left',
                    sortable: false,
                    value: 'id'
                },
            ];

            this.listLanguageNames.forEach((lang) => {
                headers.push({
                    text: lang.id,
                    align: 'left',
                    sortable: false,
                    value: 'translations.' + lang.id + '.value'
                });
            })

            return headers;
        }
    },

    mounted() {
        this.clearForm();
        if (this.id > 0) {
            this.fetchItem();
        }
    },

    methods: {
        clearForm() {
            this.slug = null
            this.multiple = false;
            this.required = false;
            this.type = null;
            this.listLanguageNames.forEach((lang) => {
                lang.value = null;
            })
        },

        fetchEntities() {
            this.loading = true;
            this.$http.get(`admin/eav/type`)
                .then(response => {
                    this.entities = response.body.data;
                })
                .catch(errorResp => {
                    this.$toastr.error(errorResp);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        fetchItem() {
            this.loading = true;
            this.$http.get(`admin/eav/type/${this.entity_type_slug}/property/${this.id}`)
                .then(response => {
                    this.processResponse(response)
                })
                .catch(errorResp => {
                    this.$toastr.error(errorResp);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        postItem() {
            this.loading = true;
            let data = {
                slug: this.slug,
                type: this.type,
                sort: this.sort,
                entity_id: this.linked_entity_type_id,
                multiple: this.multiple,
                required: this.required,
                searchable: this.searchable,
            };

            this.listLanguageNames.forEach((lng) => {
                if (!data[lng.id]) {
                    data[lng.id] = {};
                }

                data[lng.id]['name'] = this.translations[lng.id].name;
            })

            data.enums = [];

            this.enums.map((item) => {
                let itemData = {
                    id: item.id,
                    translations: {}
                };

                this.listLanguageNames.forEach((lng) => {
                    if (!itemData[lng.id]) {
                        itemData[lng.id] = {};
                    }
                    itemData[lng.id]['value'] = item.translations[lng.id]?.value ?? '';
                })

                data.enums.push(itemData)
            })

            if(this.type === 'dictionary') {
                data.linked_entity_type_id = this.linked_entity_type_id;
            }

            if (this.id > 0) {
                this.$http.put(`admin/eav/type/${this.entity_type_slug}/property/${this.id}`, data)
                    .then(response => {
                        this.processResponse(response);
                        this.$toastr.success(this.$t('eav_property.updated'));
                    })
                    .catch(errorResp => {
                        this.$toastr.error(this.$t('eav_property.error'));
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                this.$http.post(`admin/eav/type/${this.entity_type_slug}/property`, data)
                    .then(response => {
                        this.processResponse(response);
                        this.$toastr.success(this.$t('eav_property.created'));
                        this.$router.push({name: 'eav.type.property.edit', params: {entity_type_slug: this.entity_type_slug, id: response.body.data.id}});
                    })
                    .catch(errorResp => {
                        this.$toastr.error(this.$t('eav_property.error'));
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },

        processResponse(response) {
            let data = response.body.data;

            this.name = data.name;
            this.sort = data.sort;
            this.slug = data.slug;
            this.multiple = data.multiple;
            this.required = data.required;
            this.searchable = data.searchable ?? false;
            this.type = data.type;
            this.enums = data.enums;
            this.linked_entity_type_id = data.linked_entity_type?.id ?? null

            this.listLanguageNames.forEach((lang) => {
                this.translations[lang.id].name = data.translations[lang.id].name;
                this.$set(this, 'translations', this.translations);
            })
        },

        openEnumDialog(listItem, index = -1) {
            if (listItem == null) {
                let translations = {};

                this.listLanguageNames.forEach((lang) => {
                    translations[lang.id] = '';
                })

                listItem = {
                    id: null,
                    translations: translations,
                };
            }

            listItem.index = index;

            this.editingListItem = listItem;
        },

        onEnumDeleteBtnClick(index) {
            this.enums.splice(index, 1);
        },

        closeListItemDialog() {
            this.editingListItem = null;
        },

        onEnumInput(value) {
            if (value) {
                let i = value.index;
                delete value.index;
                if (i === -1) {
                    this.enums.push(value)
                } else {
                    this.$set(this.enums, i, value);
                }
            }

            this.closeListItemDialog();
        },
    }
}
</script>
